import React, { Children, useState } from 'react';
import { createPortal } from 'react-dom'
import './Services.css';

const Services = () => {
    const [showWebDesignPopup, setShowWebDesignPopup] = useState(false);
    const [showMaintenancePopup, setShowMaintenancePopup] = useState(false);

    const handleWebDesignClick = () => setShowWebDesignPopup(true);
    const handleMaintenanceClick = () => setShowMaintenancePopup(true);

    const closeModal = () => {
        setShowWebDesignPopup(false);
        setShowMaintenancePopup(false);
    };

    const Modal = ({ children, closeModal }) => {
        return createPortal(
            <div className='modal-overlay'>
                <div className='modal'>
                    <button className='close-modal' onClick={closeModal}>X</button>
                    {children}
                </div>
            </div>,
            document.getElementById('modal-root')
        );
    };

    return (
        <section className='services-section' id='services'>
            <h2>What We Offer</h2>
            <div className='services-container'>
                <div className='service-item'>
                    <h3>Web Design</h3>
                    <p>Custom, responsive websites designed to elevate your business and attract customers.</p>
                    <button className='more-info-btn' onClick={handleWebDesignClick}>More Info</button>
                </div>
                <div className='service-item'>
                    <h3>Maintenance</h3>
                    <p>Reliable, ongoing support to ensure your website stays secure and up-to-date.</p>
                    <button className='more-info-btn' onClick={handleMaintenanceClick}>More Info</button>
                </div>
            </div>

            {showWebDesignPopup && (
                <Modal closeModal={closeModal}>
                    <h3>Web Design Services</h3>
                    <p>We build custom, responsive websites that are designed to meet your unique business needs. Whether you’re starting with a small site or looking for a full-featured online store, we have a package for you.</p>
                    <p><strong>Starting at:</strong> $500 for a basic website.</p>
                    <p><strong>Packages:</strong></p>
                    <ul>
                        <li><strong>Basic Package:</strong> $500 - A clean, professional website with up to 3 pages. Perfect for small businesses or personal portfolios. Includes mobile-friendly design.</li>
                        <li><strong>Standard Package:</strong> $800 - Up to 6 pages, mobile-friendly, with basic Search Engine Optimization (SEO) to help customers find you on Google. Ideal for growing businesses that need more visibility.</li>
                        <li><strong>Premium Package:</strong> Starting at $1,200+ - A fully customized site with advanced SEO, e-commerce integration, and interactive features. Great for businesses that need custom features like online stores, booking systems, or unique design elements.</li>
                    </ul>
                    <p>Note: Prices may vary depending on custom requirements and additional features you may need.</p>
                    <button className='modal-contact-btn' onClick={() => {
                        closeModal();
                        window.location.href = '#contact';
                    }}>
                        Inquire About Pricing
                    </button>
                </Modal>
            )}
            {showMaintenancePopup && (
                <Modal closeModal={closeModal}>
                    <h3>Maintenance Services</h3>
                    <p>Our maintenance packages provide ongoing support to keep your website secure, updated, and running smoothly.</p>
                    <p><strong>Packages:</strong></p>
                    <ul>
                        <li><strong>Basic Maintenance:</strong> $100/month - Regular updates, backups, and essential security checks to ensure your site is always up to date and protected.</li>
                        <li><strong>Standard Maintenance:</strong> $150/month - Includes everything in the Basic package, plus content updates and minor fixes as needed, helping you keep your site fresh and relevant.</li>
                        <li><strong>Premium Maintenance:</strong> $250/month - Our most comprehensive package, offering priority support, custom feature updates, and tailored solutions for your evolving needs.</li>
                    </ul>
                    <p>Pricing depends on the size and complexity of your website. We’re here to help you choose the right plan that fits your needs and budget.</p>
                    <button className='modal-contact-btn' onClick={() => {
                        closeModal();
                        window.location.href = '#contact';
                    }}>
                        Inquire About Pricing
                    </button>
                </Modal>
            )}
        </section>
    );
};

export default Services;
