// useIntersectionObserver.js 

import { useEffect, useRef, useState } from 'react'

export const useIntersectionObserver = () => {
    const [isVisible, setisVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setisVisible(true);
                } else {
                    setisVisible(false);
                }
            },
            { threshold: 0.1}
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return [ref, isVisible];
};