// Footer.js

import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className='footer-container'>
            <div className='footer-content'>
                <div className='footer-left'>
                    <p>© 2024 The World Web Wizards</p>
                </div>
                <div className='footer-center'>
                    <div className='footer-links'>
                        <a href='#services'>Services</a>
                        <a href='#contact'>Contact</a>
                    </div>
                </div>
                <div className='footer-right'>
                    <div className='footer-social'>
                        <a href='https://www.linkedin.com/in/johnperkins0831/' target='_blank' rel='noreferrer'>LinkedIn</a>
                        <a href='https://jdperkins.netlify.app/' target='_blank' rel='noreferrer'>Portfolio</a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;