// App.js

import React from "react";
import Header from "./components/Header";
import Services from "./components/Services";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import withFadeIn from './hooks/withFadeIn';
import './App.css';

const HeaderWithFadeIn = withFadeIn(Header);
const ServicesWithFadeIn = withFadeIn(Services);
const AboutwithFadeIn = withFadeIn(About);
const ContactWithFadeIn = withFadeIn(Contact);
const FooterWithFadeIn = withFadeIn(Footer);

function App() {
    return (
        <div className="App">
            <HeaderWithFadeIn />
            <ServicesWithFadeIn />
            <AboutwithFadeIn />
            <ContactWithFadeIn />
            <FooterWithFadeIn />
        </div>
    );
};

export default App;