// withFadein.js

import React from "react";
import { useIntersectionObserver } from "../hooks/useIntersectionObserver";

const withFadein = (WrappedComponent) => {
    return (props) => {
        const [ref, isVisible] = useIntersectionObserver();

        return (
            <div ref={ref} className={isVisible ? 'fade-in' : 'hidden'}>
                <WrappedComponent {...props} />
            </div>
        );
    };
};

export default withFadein;