// Header.js

import React, { useEffect, useState } from 'react';
import './Header.css';

const Header = () => {
    const [text, setText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const phrases = ["Crafting Quality Websites", "That Bring Your Ideas to Life"];
    const [index, setIndex] = useState(0);
    const [typingSpeed, setTypingSpeed] = useState(100);

    useEffect(() => {
        const handleTyping = () => {
            const current = phrases[index % phrases.length];
            setText(isDeleting ? current.substring(0, text.length - 1) : current.substring(0, text.length + 1));

            if (!isDeleting && text === current) {
                setTimeout(() => setIsDeleting(true), 1000);
            } else if (isDeleting && text === '') {
                setIsDeleting(false);
                setIndex(index + 1);
            }

            if (isDeleting) {
                setTypingSpeed(50);
            } else {
                setTypingSpeed(100);
            }
        };

        const timeout = setTimeout(handleTyping, typingSpeed);
        return () => clearTimeout(timeout);
    }, [text, isDeleting, index, typingSpeed]);

    return (
        <header className='header-container'>
            <div className='logo'>
                <img src='/logo.png' alt='World Web Wizard logo' />
            </div>
            <div className='wizard-image'>
                <img src="/wizard.png" alt="floating wizard" className="flying-wizard" />
            </div>
            <div className='hero-text'>
                <h1>The World Web Wizards</h1>
                <p>{text}</p>
                <div className='button-group'>
                    <a href='#services' className='cta-btn'>Get Started</a>
                    <a href='#contact' className='contact-btn'>Contact Us</a>
                </div>
            </div>
        </header>
    );
};

export default Header;