// Contact.js

import React, { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import './Contact.css';
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Contact = () => {
    const formInitialDetails = {
        name: '',
        email: '',
        message: ''
    };

    const [formDetails, setFormDetails] = useState(formInitialDetails);
    const [buttonText, setButtonText] = useState('Send');
    const [loading, setLoading] = useState(false);
    const form = useRef();

    const onFormUpdate = (category, value) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);
        setButtonText('Sending...');

        emailjs
            .sendForm(
                process.env.REACT_APP_YOUR_SERVICE_ID,
                process.env.REACT_APP_YOUR_TEMPLATE_ID,
                form.current,
                process.env.REACT_APP_YOUR_PUBLIC_KEY
            )
            .then(() => {
                toast.success("Your message has been sent successfully!", { position: "top-right"});
                setLoading(false);
                setButtonText("Sent");
                setFormDetails(formInitialDetails);
                setTimeout(() => {
                    setButtonText("Send");
                }, 3000);
            }, (error) => {
                toast.error("Failed to send the message, please try again.", { position: "top-right"});
                setLoading(false);
                setButtonText("Send");
            });
    };

    return (
        <section className="contact-section" id="contact">
            <div className="contact-content">
                <h2>Contact Us</h2>
                <p>If you have any questions or would like to discuss your web design project, feel free to reach out!</p>
                <form ref={form} onSubmit={sendEmail} className="contact-form" name="contact" netlify="true">
                    <div className="form-group">
                        <input
                            type="text"
                            name="name"
                            placeholder=" "
                            value={formDetails.name}
                            onChange={(e) => onFormUpdate("name", e.target.value)}
                            required
                        />
                    <label>Name</label>
                    </div>
                    <div className="form-group">
                        <input
                            type="email"
                            name="email"
                            placeholder=" "
                            value={formDetails.email}
                            onChange={(e) => onFormUpdate("email", e.target.value)}
                            required
                        />
                        <label>Email</label>
                    </div>
                    <div className="form-group">
                        <textarea
                            name="message"
                            placeholder=" "
                            value={formDetails.message}
                            onChange={(e) => onFormUpdate("message", e.target.value)}
                            required
                        />
                        <label>Message</label>
                    </div>
                    <button type="submit" disabled={loading}>
                        <span>{loading ? "Sending..." : buttonText}</span>
                    </button>
                </form>
                <div className="contact-info">
                    <p>Email: <a href="mailto:theworldwebwizards@gmail.com">theworldwebwizards@gmail.com</a></p>
                    <p>Phone: (872) 267-3357</p>
                    <p>Founder's LinkedIn: <a href="https://www.linkedin.com/in/johnperkins0831/" target="_blank" rel="noreferrer">John Perkins</a></p>
                    <p>Portfolio: <a href="https://jdperkins.netlify.app/" target="_blank" rel="noreferrer">John Perkins Portfolio</a></p>
                </div>
            </div>
            <ToastContainer />
        </section>
    );
};

export default Contact;