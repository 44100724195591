// About.js

import React from "react";
import './About.css';

const About = () => {
    return (
        <section className='about-section' id='about'>
            <div className='about-content'>
                <h2>About The World Web Wizards</h2>
                <p>
                    At World Web Wizards, we’re dedicated to delivering high-quality, custom web solutions tailored to meet the unique needs of every client. Our team prides itself on a relentless pursuit of excellence, whether we're designing sleek, responsive websites or implementing solutions that enhance functionality and user experience.
                </p>
                <p>
                    We understand that every project is unique, and we take a personal approach with each client, ensuring that your ideas are brought to life with precision, creativity, and professionalism. Our commitment to continuous learning and staying ahead of industry trends allows us to offer modern, innovative web designs that make your business stand out.
                </p>
                <p>
                    Unlike large corporations, we provide one-on-one attention with competitive pricing that never compromises quality. We work closely with you from start to finish to ensure your website reflects your vision, all while keeping costs transparent and reasonable.
                </p>
                <p>
                    At World Web Wizards, we’re not just building websites—we’re crafting digital experiences designed to elevate your business.
                </p>
            </div>
        </section>
    );
};

export default About;
